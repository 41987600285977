import { useState } from 'react';
import { Link } from 'react-router-dom';
import type { Offer } from 'types/offer';
import formatDate from 'utils/formatDate';
import { isProspecting } from 'utils/offer';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import Card from 'components/Card';
import CopyButton from 'components/CopyButton';
import SendSponsorshipApplicationModal from 'components/SendSponsorshipApplicationModal';
import { Toast } from '@teamsnap/snap-ui';

const url = (sponsorshipApplicationMagicToken: string) =>
  `${global.location.origin}/qualify/${sponsorshipApplicationMagicToken}`;

type SurveyCardProps = {
  offer: Offer;
  refetchSponsorship: () => void;
};

function SurveyCard(props: SurveyCardProps) {
  const { offer, refetchSponsorship } = props;
  const [surveyEmailsSent, setSurveyEmailsSent] = useState(false);
  const {
    campaign,
    sponsorshipApplicationMagicToken,
    sponsorshipApplicationSentAt,
  } = offer;
  const offerIsProspecting = isProspecting(offer);

  const handleOnSendSurveyEmails = () => {
    refetchSponsorship();
    setSurveyEmailsSent(true);
    setTimeout(() => setSurveyEmailsSent(false), 3000);
  };

  return (
    <Card data-test="survey">
      <h2 className="card-title">
        <Link
          to={`${CAMPAIGN_PLANNER_PATH}/${campaign.sponsor.id}/campaigns/${campaign.id}/sponsorship-application`}
        >
          Sponsorship Application
        </Link>
      </h2>
      {offerIsProspecting && (
        <>
          <SendSponsorshipApplicationModal
            id="send-pre-qual-survey"
            offers={[offer]}
            toggleClassName="btn btn-primary mr-2"
            onSend={handleOnSendSurveyEmails}
            sponsorshipApplicationOfferId={offer.id}
          />
          {!!sponsorshipApplicationSentAt && (
            <CopyButton
              content={url(sponsorshipApplicationMagicToken)}
              label="Application Link"
            />
          )}
        </>
      )}
      {surveyEmailsSent && (
        <Toast type="success" inline className="sui-mt-2">
          Application emails sent successfully!
        </Toast>
      )}
      <p className="mt-3">
        {sponsorshipApplicationSentAt ? (
          <>
            {`The application was sent to the league on ${formatDate(
              sponsorshipApplicationSentAt,
            )}. `}
          </>
        ) : (
          `The application ${offerIsProspecting ? 'has' : 'was'} not ${
            offerIsProspecting ? 'been ' : ''
          }sent to the league${offerIsProspecting ? ' yet' : ''}!`
        )}
      </p>
    </Card>
  );
}

export default SurveyCard;
