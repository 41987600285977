import React, { useState } from 'react';
import { all, append, contains, filter, isEmpty, map, without } from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { pluralizedTense } from 'utils/string';
import { pluckId } from 'utils/misc';
import {
  sortOffersBySurveyColV2,
  sortOffersBySPName,
  sortOffersByOrganization,
} from 'utils/offer';
import Alert from 'components/Alert';
import SendSponsorshipApplicationModal from 'components/SendSponsorshipApplicationModal';
import RemoveFromCampaignModal from 'components/RemoveFromCampaignModal';
import Icon from 'components/Icon';
import { Toast } from '@teamsnap/snap-ui';
import AddProspectsDropdown from './AddProspectsDropdown';
import AddProspectsModal from './AddProspectsModal';
import ProspectsTableRow from './ProspectsTableRow';
import AddHubspotCampaignPropertyToContactsModal from './AddHubspotCampaignPropertyToContactsModal';
import MarkAsQualifiedModal from './MarkAsQualifiedModal';

const titleCopy = (offers) =>
  `${offers.length} ${pluralizedTense(offers.length, 'prospect')}`;
const numberOfOrganizations = (organizationCount) =>
  `${organizationCount} ${pluralizedTense(organizationCount, 'organization')}`;
const bulkButtonCopy = (selectedOffers) =>
  `With ${selectedOffers.length} Selected ${pluralizedTense(
    selectedOffers.length,
    'Sponsorable Property',
  )}`;

const ProspectsTable = ({
  campaignId,
  campaignName,
  displayProspectsDropdown,
  hasSurveyQuestions,
  offers,
  onAddProspects,
  onMarkAsQualified,
  onRemoveFromCampaign,
  onSendPreQualSurvey,
  sponsorId,
  organizationCount,
}) => {
  const [selectedOfferIds, setSelectedOfferIds] = useState([]);
  const [shouldShowHubspotSyncAlert, setShouldShowHubspotSyncAlert] =
    useState(false);
  const [sortField, setSortField] = useState('survey');
  const [surveyEmailsSent, setSurveyEmailsSent] = useState(false);

  const allOfferIds = pluckId(offers);
  const selectedIdsSet = new Set(selectedOfferIds);
  const allSelected = all((id) => selectedIdsSet.has(id), allOfferIds);
  const selectedOffers = filter(
    ({ id }) => contains(id, selectedOfferIds),
    offers,
  );
  const tableSortsMap = {
    survey: sortOffersBySurveyColV2,
    sponsorablePropertyName: sortOffersBySPName,
    organization: sortOffersByOrganization,
  };

  const removeOfferIdAndRefetch = () => {
    setSelectedOfferIds([]);
    onRemoveFromCampaign();
  };

  const handleToggle = (offerId) => {
    const includesOfferId = contains(offerId, selectedOfferIds);
    const removeOfferId = without([offerId], selectedOfferIds);
    const addOfferId = append(offerId, selectedOfferIds);

    return () =>
      setSelectedOfferIds(() => (includesOfferId ? removeOfferId : addOfferId));
  };

  const handleToggleAll = () => {
    setSelectedOfferIds(allSelected ? [] : allOfferIds);
  };

  const handleOnSendSponsorshipApplication = () => {
    setSelectedOfferIds([]);
    setSurveyEmailsSent(true);
    setTimeout(() => setSurveyEmailsSent(false), 3000);
    onSendPreQualSurvey();
  };

  const headerButtonStyle = {
    backgroundColor: 'Transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'flex',
    'flex-direction': 'row',
    'justify-content': 'flex-end',
    textAlign: 'left',
  };

  const columnHeaders = [
    <th scope="col" className="align-middle" key="checkbox">
      <input
        checked={allSelected}
        data-test="all-prospecting-toggle"
        onChange={handleToggleAll}
        type="checkbox"
      />
    </th>,
    <th scope="col" className="align-middle pr-1" key="organization">
      <button
        style={headerButtonStyle}
        type="button"
        onClick={() => setSortField('organization')}
      >
        Organization
        {sortField === 'organization' && <Icon value="chevron-up" />}
      </button>
    </th>,
    <th scope="col" className="align-middle pr-1" key="sponsorableProperty">
      <button
        style={headerButtonStyle}
        type="button"
        onClick={() => setSortField('sponsorablePropertyName')}
      >
        Sponsorable Property
        {sortField === 'sponsorablePropertyName' && <Icon value="chevron-up" />}
      </button>
    </th>,
    <th
      scope="col"
      className="align-middle pl-1"
      key="report-column"
      aria-label="report-column"
    />,
    <th
      scope="col"
      className="align-middle d-none d-lg-table-cell"
      key="location"
    >
      Location
    </th>,
    <th scope="col" className="align-middle" key="survey">
      <button
        style={headerButtonStyle}
        type="button"
        onClick={() => setSortField('survey')}
      >
        Survey
        {sortField === 'survey' && <Icon className="ml-1" value="chevron-up" />}
      </button>
    </th>,
    <th scope="col" className="align-middle text-center" key="hubspot">
      Hubspot
    </th>,
  ];

  return (
    <>
      <div
        className="d-flex mb-2 justify-content-between align-items-center"
        data-test="prospects-table"
      >
        <p className="m-0 sui-font-bold">
          {titleCopy(offers)}
          <br />({numberOfOrganizations(organizationCount)})
        </p>
        <span>
          {displayProspectsDropdown ? (
            <AddProspectsDropdown
              campaignId={campaignId}
              campaignName={campaignName}
              onAdd={onAddProspects}
              sponsorId={sponsorId}
            />
          ) : (
            <AddProspectsModal
              campaign={{ id: campaignId, name: campaignName }}
              onAdd={onAddProspects}
              toggleProps={{
                className: 'btn btn-large btn-secondary',
                children: 'Add by Name',
              }}
            />
          )}
          <div className="dropdown d-inline-block ml-2">
            <button
              className="btn btn-large btn-secondary dropdown-toggle"
              disabled={isEmpty(selectedOffers)}
              data-toggle="dropdown"
              id="bulk-prospecting-dropdown-menu-button"
              type="button"
            >
              {bulkButtonCopy(selectedOffers)}
            </button>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="bulk-prospecting-dropdown-menu-button"
            >
              {!!selectedOffers.length && (
                <SendSponsorshipApplicationModal
                  id="bulk-send-sponsorship-application-modal"
                  offers={selectedOffers}
                  hasSurveyQuestions={hasSurveyQuestions}
                  onSend={handleOnSendSponsorshipApplication}
                  toggleClassName="btn btn-link dropdown-item"
                  sponsorshipApplicationOfferId={selectedOfferIds[0]}
                />
              )}
              <AddHubspotCampaignPropertyToContactsModal
                id="add-hubspot-campaign-property-to-contacts"
                offers={selectedOffers}
                toggleClassName="btn btn-link dropdown-item"
                showHubspotSyncAlert={setShouldShowHubspotSyncAlert}
              />
              <MarkAsQualifiedModal
                id="bulk-mark-as-qualified-modal"
                offers={selectedOffers}
                onSubmit={onMarkAsQualified}
                toggleClassName="btn btn-link dropdown-item"
              />
              <RemoveFromCampaignModal
                offers={selectedOffers}
                prefix="bulk-prospects"
                onRemove={removeOfferIdAndRefetch}
              />
            </div>
          </div>
        </span>
      </div>
      {shouldShowHubspotSyncAlert && (
        <Alert alertStyle="success">
          Sync to Hubspot started. This may take some time. Benchmark is 2
          minutes for 100 sponsorable properties.
        </Alert>
      )}
      {surveyEmailsSent && (
        <Toast type="success" inline>
          Survey emails sent successfully!
        </Toast>
      )}
      {offers.length ? (
        <table
          className="table table-responsive"
          data-test="prospecting-table-offers"
        >
          <thead>
            <tr>{map((header) => header, columnHeaders)}</tr>
          </thead>
          <tbody>
            {map(
              (offer) => (
                <ProspectsTableRow
                  colLength={columnHeaders.length}
                  key={offer.id}
                  handleToggle={handleToggle(offer.id)}
                  offer={offer}
                  selectedOfferIds={selectedOfferIds}
                />
              ),
              tableSortsMap[sortField](offers),
            )}
          </tbody>
        </table>
      ) : (
        <p className="text-center" data-test="no-prospecting-message">
          No prospecting sponsorships exist!
        </p>
      )}
    </>
  );
};

ProspectsTable.propTypes = {
  campaignId: PropTypes.number.isRequired,
  campaignName: PropTypes.string.isRequired,
  displayProspectsDropdown: PropTypes.bool,
  hasSurveyQuestions: PropTypes.bool.isRequired,
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  onAddProspects: PropTypes.func.isRequired,
  onMarkAsQualified: PropTypes.func.isRequired,
  onRemoveFromCampaign: PropTypes.func.isRequired,
  onSendPreQualSurvey: PropTypes.func.isRequired,
  sponsorId: PropTypes.number.isRequired,
  organizationCount: PropTypes.number,
};

ProspectsTable.defaultProps = {
  displayProspectsDropdown: false,
  organizationCount: 0,
};

export default ProspectsTable;
